import { UserList } from './UserList.mjs';
import { ManageUser } from './ManageUser.mjs';

export class Users {
    constructor(Controller) {
        /*
        const eventHandler = new K.Observer(['navigate']);
        this.on = eventHandler.listen;
        this.notify = eventHandler.notify;
        */
        this.Controller = Controller;
        
        this.account_uuid = undefined;

        const eventHandler = new K.Observer(['abort']);
        this.on = eventHandler.listen;
        this.notify = eventHandler.notify;
        this.Container = new K.Elements.Div({'className':'k-users'});
        this.UserListInstance = undefined;
        this.ManageUserInstance = undefined;
    }
    errorHandler(response) {
        console.error(response);
    }
    destroy() {
        if (typeof (this.ManageUserInstance) !== "undefined") {
            this.ManageUserInstance.destroy();
            delete this.ManageUserInstance;
        }
        if (typeof (this.UserListInstance) !== "undefined") {
            this.UserListInstance.destroy();
            delete this.UserListInstance;
        }
        this.Container.clear();
    }
    openUser(uuid) {
        K.Router.navigate('User', '/account/' + this.account_uuid + '/user/' + uuid);
        
    }
    
    renderUserHome(){
        this.destroy();
        if (typeof (this.ManageUserInstance) === "undefined") {
            this.ManageUserInstance = new ManageUser(this);
            this.ManageUserInstance.on('finished', function () {
                K.Router.navigate('Users', '/account/' + this.account_uuid + '/users');
                this.render();
            }.bind(this));
        }
        this.Container.append(this.ManageUserInstance.render(uuid));
    }
    
    renderManageUser(account_uuid, user_uuid, data) {
        this.account_uuid = account_uuid;
        this.user_uuid = user_uuid;
        this.destroy();
        if (typeof (this.ManageUserInstance) === "undefined") {
            this.ManageUserInstance = new ManageUser(this);
            this.ManageUserInstance.on('abort', function () {
                K.Router.navigate('Users', '/account/' + this.account_uuid + '/users');
                //this.render();
            }.bind(this));
            this.ManageUserInstance.on('finished', function () {
                K.Router.navigate('Users', '/account/' + this.account_uuid + '/users');
                //this.render();
            }.bind(this));
        }
        //K.Router.navigate('User', '/account/' + this.account_uuid + '/user/' + user.uuid + '/edit');
        this.Container.append(this.ManageUserInstance.render(account_uuid, user_uuid, data));
        return this.Container;
    }
    renderUserList() {
        this.destroy();
        if (typeof (this.UserListInstance) === "undefined") {
            this.UserListInstance = new UserList(this);
        }
        //K.Router.navigate('Users', '/account/' + this.account_uuid + '/users');
        this.Container.append(this.UserListInstance.render());
    }
  
}
