
export class TopBar {
	constructor(args) {
		const eventHandler = new K.Observer(['navigate','logout']);
		this.on = eventHandler.listen;
		this.notify = eventHandler.notify;	
	}
	render(){
		
		const TopBar = K.DOM.createElement('div',{'className':'k-topbar'});
		
		let logoutButton = new K.Elements.Button({
			'type': 'button',
			'buttonType':'link',
			'block': false,
			'icon':'power-off',
			'buttonText': 'Logga ut'
		});
		
		TopBar.append([
			K.DOM.createElement('div',{'className':'k-topbar-logo'}).append([
				K.DOM.createElement('img',{'className':'k-logo','src':'/assets/logo.png'})
			])
		]);
		const Tabs = new K.Components.Tabs({
			'children':[
				K.DOM.createElement('a',{'className':'k-tab','data-action':'/','children':[
					K.DOM.createElement('i',{'className':'fa fa-home'}),
					K.DOM.createTextNode('Start')
				]}),
				K.DOM.createElement('a',{'className':'k-tab','data-action':'/accounts','children':[
					K.DOM.createElement('i',{'className':'fa fa-briefcase'}),
					K.DOM.createTextNode('Konton')
				]}),
				K.DOM.createElement('a',{'className':'k-tab','data-action':'/routines','children':[
					K.DOM.createElement('i',{'className':'fa fa-microchip'}),
					K.DOM.createTextNode('Rutiner')
				]})
			]
		});
		let dirs = K.Router.getDirs();
		let baseDir = dirs[0] ?? null;
		let active;
		switch(baseDir){
			case "accounts":
			case "account":
				active = Tabs.querySelector('.k-tab[data-action="/accounts"]');
				if(active){
					Tabs.setActiveTab(active);
				}
			break;
			default:
				active = Tabs.querySelector('.k-tab[data-action="/'+baseDir+'"]');
				if(active){
					Tabs.setActiveTab(active);
				}
		}
		
		K.Events.on(logoutButton,'click',(e) => this.notify( 'logout'));
		Tabs.on('tabItemClick', function(data){
			K.Router.navigate('Dataset', data.action);
			this.notify('navigate',data.action);
			
		}.bind(this));
		
		const Nav = K.DOM.createElement('div',{'className':'k-topbar-nav'}).append([Tabs]);
		TopBar.append(Nav);

		const Options = K.DOM.createElement('div',{'className':'k-topbar-options'}).append([logoutButton]);
		TopBar.append(Options);
		
		return TopBar;
	}
}

