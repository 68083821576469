
export class CreateAccount {
    constructor(Controller, args) {
        
        this.Controller = Controller;
        this.parentContainer = Controller.Container;
        this.Container = new K.Elements.Div({'className':'k-account-create'});
		
        const eventHandler = new K.Observer(['abort','finished']);
		this.on = eventHandler.listen;
		this.notify = eventHandler.notify;
        
    }
    errorHandler(response){
		let alertNotice = new K.Components.Alert({
			'type':'danger'
		});
        this.errorContainer.clear();
        if(typeof(response.status_text) !== "undefined"){
		    alertNotice.appendChild(K.DOM.createElement('h3',{'text':response.status_text}));
        }else{
            alertNotice.appendChild(K.DOM.createElement('h3',{'text':response.toString()}));
        }
		this.errorContainer.appendChild(alertNotice);
	}
    submit(){
        this.errorContainer.clear();
        this.submitButton.disabled = true;
        this.cancelButton.disabled = true;
        
        let alertNotice = new K.Components.Alert({
			'type':'primary'
		});
        alertNotice.appendChild(K.DOM.createElement('h3',{'children':[
            K.DOM.createElement('i',{'className':'fa fa-circle-notch fa-spin k-mr-s'}),
            K.DOM.createTextNode('Inläsning pågår...')
        ]}));        
		this.errorContainer.appendChild(alertNotice);

        new K.Request.POST(K.Config.api.url+'/create/account.json',{
                'name':this.inputAccountName.value,
                'active':this.enableAccountToggle.checked ? 1 : 0,
                'date_start':this.accountStartDateInput.value,
                'date_expires':this.accountExpiresDateInput.value
            })
            .then((account)=> {
                  this.notify('finished',account);
            })
            .catch((err) => this.errorHandler(err));
        return false;
    }
    destroy(){
		
	}
    render(){
        this.Container.clear();
        
		this.Top = new K.Elements.Div({'className':'k-account-create-top'}).appendTo(this.Container);
		this.Content = new K.Elements.Div({'className':'k-account-create-form'}).appendTo(this.Container);

        this.errorContainer = new K.Elements.Div({'className':'k-error-panel'});
		
        let listELement = new K.Elements.List();
        
        listELement.addChildNode(K.DOM.createElement('li',{'text':'Om du inte fyller i ett datumvärde i fältet "Aktivt t.o.m" och "Aktivt fr.o.m"  så innebär det att kontot är aktivt tillsvidare. Det är alltså inget krav.'}));
        const InfoCol = new K.Elements.Col(4, {padded:true}).append([
            K.DOM.createElement('h3',{'text':'Hjälp'}),
            listELement
        ]);
        const FormCol = new K.Elements.Col(8, {padded:true});
        
        this.submitButton = new K.Elements.Button({
            'type': 'submit',
            'buttonType':'primary',
            'block': true,
            'disabled': true,
            'buttonText': 'Skapa konto'
        });
        this.cancelButton = new K.Elements.Button({
            'type': 'reset',
            'buttonType':'default',
            'block': true,
            'buttonText': 'Avbryt'
        });
        this.createAccountForm = new K.Elements.Form({
            name:'account-create',
            id:'k-account-create-form'
        })
        .preventDefault()
        .on('submit', () => this.submit());
        
        this.inputAccountName = new K.Elements.InputText({
            'placeholder': 'Välj ett namn på kontot',
            'className':'k-block'
        });
        this.enableAccountToggle = new K.Elements.CheckboxSlider({
			'text':'Aktivt konto',
			'checked':true
		});
        this.accountExpiresDateInput = new K.Elements.Input({
            'type': 'date',
            'className':'k-block'
        });
        this.accountStartDateInput = new K.Elements.Input({
            'type': 'date',
            'className':'k-block'
        });
		
        this.inputAccountName.on('change', function(){
            this.errorContainer.clear();
            this.submitButton.disabled = this.inputAccountName.value.length === 0;
            this.cancelButton.disabled = false;
        }.bind(this));
        this.createAccountForm.append([
            new K.Elements.Div({'className':'k-row'}).append([
                new K.Elements.Col(12, {padded:true}).append([
                    K.DOM.createElement('h2',{'text':'Namn på konto'}),
                    this.inputAccountName,
                    K.DOM.createElement('h2',{'text':'Status'}),
                    this.enableAccountToggle,
                    K.DOM.createElement('hr', {'className':'k-thin k-large'}),
                    K.DOM.createElement('h3',{'text':'Aktivt fr.o.m'}),
                    this.accountStartDateInput,
                    K.DOM.createElement('hr', {'className':'k-thin k-large'}),
                    K.DOM.createElement('h3',{'text':'Aktivt t.o.m'}),
                    this.accountExpiresDateInput,
                    this.errorContainer
                ])
            ]),
            new K.Elements.Div({'className':'k-row'}).append([
                new K.Elements.Col(6, {padded:true}).append([this.submitButton]),
                new K.Elements.Col(6, {padded:true}).append([this.cancelButton])
            ])
        ]);
        FormCol.append(this.createAccountForm);
        this.Top.append([
            K.DOM.createElement('div',{'className':'k-header'}).append([
                K.DOM.createElement('h1',{'children':[
                    K.DOM.createElement('i',{'className':'fa fa-briefcase'}),
                    K.DOM.createTextNode('Nytt konto')
                ]})
            ])            
        ]);
        this.Content.append([
            new K.Elements.Div({'className':'k-row'}).append([
                FormCol,
                InfoCol
            ])
            
        ]);
        
        this.cancelButton.on('click',(e) => this.notify('abort'));

        return this.Container;

    }
}
