import { LoginForm } from '../App/Login/LoginForm.mjs';
import { PasswordResetForm } from '../App/Login/PasswordResetForm.mjs';
import { NewPasswordForm } from '../App/Login/NewPasswordForm.mjs';

export class Login {
    constructor(args) {
        const eventHandler = new K.Observer(['login','signup']);
		this.on = eventHandler.listen;
		this.notify = eventHandler.notify;
        this.Container = new K.Elements.Div({'className':'k-login'});
    }
    errorHandler(response){
		let alertNotice = new K.Components.Alert({
			'type':'danger'
		});
		if(typeof(response.status_text) !== "undefined"){
		    alertNotice.appendChild(K.DOM.createElement('h3',{'text':response.status_text}));
        }else{
            alertNotice.appendChild(K.DOM.createElement('h3',{'text':response.toString()}));
        }
		this.errorContainer.appendChild(alertNotice);
        
        console.error(response.status_text);
        
	}
    renderLogin(){
        if(!this.loginForm){
            this.loginForm = new LoginForm();
            this.loginForm.on('login', (e) => this.notify('login'));
            this.loginForm.on('signup', (e) => this.openView('signup'));
            this.loginForm.on('password-reset', (e) => this.openView('password-reset'));
            this.loginForm.on('error', (err) => this.errorHandler(err));
        }
        this.formContainer.clear().append(this.loginForm.render());
        
    }
    renderPasswordResetForm(){
        if(!this.passwordResetForm){
            this.passwordResetForm = new PasswordResetForm();
            this.passwordResetForm.on('abort', () => this.openView('login'));
            this.passwordResetForm.on('success', (data) => {
                this.openView('login');
                let alertNotice = new K.Components.Alert({'type':'success'});
                alertNotice.appendChild(K.DOM.createElement('h3',{'text':'Återställning av lösenord lyckades.'}));
                alertNotice.appendChild(K.DOM.createElement('p',{'text':'Vi har skickat ett e-postmeddelande till din e-postadress. Kolla i skräpkorgen ifall meddelandet inte har nått dig inom 5 minuter.'}));
                this.errorContainer.appendChild(alertNotice);
            });
            //this.passwordResetForm.on('password-reset', this.openView.bind(this,'password-reset'));
        }
        this.formContainer.clear().append(this.passwordResetForm.render());
    }
    renderNewPassword(){
        if(!this.newPasswordForm){
                    
            this.newPasswordForm = new NewPasswordForm();
            this.newPasswordForm.on('abort', () => this.openView('login'));
            this.newPasswordForm.on('success', (data) => {
                this.openView('login');
                let alertNotice = new K.Components.Alert({'type':'success'});
                alertNotice.appendChild(K.DOM.createElement('h3',{'text':'Återställning av lösenord lyckades.'}));
                alertNotice.appendChild(K.DOM.createElement('p',{'text':'Du ska nu kunna logga in med ditt nya lösenord.'}));
                this.errorContainer.appendChild(alertNotice);
            });
        }
        let token = K.Router.getRequestParameter('token');
        this.formContainer.clear().append(this.newPasswordForm.render(token));
    }
    openView(view){

        switch(view){
            case 'index':
            case 'login':
                this.renderLogin();
                //K.Router.navigate('Login', 'login.html');
            break;
            case 'password-reset':
               this.renderPasswordResetForm();
                //K.Router.navigate('Password reset', 'password-reset.html');
            break;
            case 'new-password':
                this.renderNewPassword();
                //K.Router.navigate('New password', 'new-password.html?token='+token);

            break;
            default:
                K.Router.open('/login.html');
        }
    }
    render() {
        
        this.formContainer = new K.Elements.Div({padded : false,id:'k-form-container'});
        this.errorContainer = new K.Elements.Div({'className':'k-error-panel'});

        this.Container.append([            
            this.formContainer,
            this.errorContainer            
        ]);
        let filename = K.Router.getRequestFileName();
        let nav = typeof(filename) !== "undefined" && filename.length > 0 ? filename.replace('.html','') : 'index';
        this.openView(nav);
        
        return this.Container;

    }
}
