import '@fortawesome/fontawesome-free/css/all.css';
import './Styles.styl';
import './Resources/logo.png';
import { Main } from './View/Page/Main.mjs';


K.Ready(function(){
	const mainInstance = new Main();
	mainInstance.render();
});
