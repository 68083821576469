const RoutineModule = {
    
    getUsageList:function(){
        return new Promise((resolve, reject) => {            
            new K.Request.GET(K.Config.api.url+'/list/usage')
            .then(resolve)
            .catch(reject);
        });
    }


};

export {RoutineModule};