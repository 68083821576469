export class LoginForm {
    constructor(args) {
        const eventHandler = new K.Observer(['login','signup','password-reset','error']);
		this.on = eventHandler.listen;
		this.notify = eventHandler.notify;
    }
    render(){
        this.loginSubmitButton = new K.Elements.Button({
            'type': 'submit',
            'buttonType':'primary',
            'block': true,
            'buttonText': 'Logga in'
        });
        this.inputUsername = new K.Elements.InputText({
            'placeholder': 'Användarnamn',
            'className':'k-block',
            'icon':'user'
        });
        this.inputPassword = new K.Elements.InputPassword({
            'placeholder': 'Lösenord',
            'className':'k-block',
            'icon':'key'
        });
        this.checkboxPersistentLogin = new K.Elements.CheckboxSlider({'text':'Håll mig inloggad på den här datorn'});
        this.loginFormContainer = new K.Elements.Form({
            name:'login',
            id:'k-login'
        })
        .preventDefault()
        .on('submit', (e) => this.submit(e));
        let passwordResetLink = K.DOM.createElement('a',{'href':'javascript:;','className':'k-text-bold','data-action':'register','innerHTML':'&raquo; Glömt lösenordet?'});
        
        this.loginFormContainer.append([
            K.DOM.createElement('img',{'className':'k-logo','src':'/assets/logo.png'}),
            K.DOM.createElement('h3',{'text':'Admin'}),
            this.inputUsername,
            this.inputPassword,
            this.checkboxPersistentLogin,
            this.loginSubmitButton,
            K.DOM.createElement('hr', {'className':'k-thin k-large'}),
            K.DOM.createElement('p',{'className':'k-text-center'}).append(passwordResetLink)
        ]);
        this.inputUsername.on('submit',(e) => this.submit(e));
        this.inputPassword.on('submit', (e) => this.submit(e));
        K.Events.on(passwordResetLink,'click',(e) => this.notify('password-reset'));
        return this.loginFormContainer;
    }
    submit(e){
        e.preventDefault();
        e.stopPropagation();
        new K.Request.POST(K.Config.api.url+'/auth/admin/login',{
            username:this.inputUsername.value,
            password:this.inputPassword.value,
            persistent:this.checkboxPersistentLogin.isChecked() ? 1 : 0
        })
        .then((data) => {
            if(data.token){
                K.Cookie.set('token',data.token, {
                    'secure':window.location.protocol.toString().toLowerCase().indexOf('https') !== -1,
                    'expires':this.checkboxPersistentLogin.isChecked() ? 7 : 1
                });
                this.notify('login', data.token);
            }else{
                this.notify('error',data);    
            }
        })
        .catch((data) => {
            this.notify('error',data);            
        });
        return false;
        
    }
}
