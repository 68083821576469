const AccountModule = {
    
    getAccountData:function(uuid){
        return new Promise((resolve, reject) => {            
            new K.Request.GET(K.Config.api.url+'/get/account/'+uuid)
            .then(resolve)
            .catch(reject);
        });
    },
    createAccount:function(properties){
        return new Promise((resolve, reject) => {
            let formData = new FormData();
            properties = properties || {};
            K.Utils.forOwn(properties, function(val, key){
                formData.append(key, val);
            });            
            new K.Request.POST(K.Config.api.url+'/create/account.json',formData)
            .then(resolve)
            .catch(reject);
        });
    },
    removeAccount:function(uuid){
        return new Promise((resolve, reject) => {
            new K.Request.POST(K.Config.api.url+'/delete/account/'+uuid+'.json')
            .then(resolve)
            .catch(reject);
        });
    },
    removeAccounts:function(uuids){
        return new Promise((resolve, reject) => {
            new K.Request.POST(K.Config.api.url+'/delete/accounts.json',{uuid:uuids})
                .then(resolve)
                .catch(reject);
        });
    },   
    renameAccount:function(uuid, name){
        return new Promise((resolve, reject) => {
            new K.Request.POST(K.Config.api.url+'/update/account/'+uuid+'.json', {
                name:name
            })
            .then(resolve)
            .catch(reject);
        });
    }


};

export {AccountModule};