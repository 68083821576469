const UserModule = {
    getUsers:function(request_args){
        return new Promise((resolve, reject) => {
            new K.Request.GET(K.Config.api.url+'/list/users/', request_args)
                .then(resolve)
                .catch(reject);
        });
    },
    
    getUser:function(uuid, request_args){
        return new Promise((resolve, reject) => {
            new K.Request.GET(K.Config.api.url+'/describe/user/'+uuid, request_args)
                .then(resolve)
                .catch(reject);
        });
    },
    getUserPermissions:function(user_uuid, request_args){
        return new Promise((resolve, reject) => {
            new K.Request.GET(K.Config.api.url+'/list/user/'+user_uuid+'/permissions/', request_args)
                .then(resolve)
                .catch(reject);
        });
    },
    createUser:function(request_args){
        return new Promise((resolve, reject) => {
            new K.Request.POST(K.Config.api.url+'/create/user.json',request_args)
            .then(resolve)
            .catch(reject);
        });
    },
    updateUser:function(uuid, request_args){
        return new Promise((resolve, reject) => {
            new K.Request.POST(K.Config.api.url+'/update/user/'+uuid+'.json', request_args)
            .then(resolve)
            .catch(reject);
        });
    },
    removeUser:function(uuid){
        return new Promise((resolve, reject) => {
            new K.Request.POST(K.Config.api.url+'/delete/user/'+uuid+'.json')
            .then(resolve)
            .catch(reject);
        });
    },
    removeUsers:function(uuids){
        return new Promise((resolve, reject) => {
            new K.Request.POST(K.Config.api.url+'/delete/users.json',{uuid:uuids})
                .then(resolve)
                .catch(reject);
        });
    },
    sendUserCredentials:function(uuid, request_args){
        return new Promise((resolve, reject) => {
            new K.Request.POST(K.Config.api.url+'/send/credentials/to/user/'+uuid, request_args)
                .then(resolve)
                .catch(reject);
        });
    }
};

export {UserModule};