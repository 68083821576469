export class PasswordResetForm {
    constructor(args) {
        const eventHandler = new K.Observer(['success','error','abort']);
		this.on = eventHandler.listen;
		this.notify = eventHandler.notify;
    }
    errorHandler(response){
		let alertNotice = new K.Components.Alert({
			'type':'danger'
		});
		console.error(response);
        if(typeof(response.status_text) !== "undefined"){
		    alertNotice.appendChild(K.DOM.createElement('h3',{'text':response.status_text}));
        }else{
            alertNotice.appendChild(K.DOM.createElement('h3',{'text':response.toString()}));
        }
		this.errorContainer.appendChild(alertNotice);
	}
    render(){
        this.errorContainer = new K.Elements.Div({'className':'k-error-panel'});
        this.submitButton = new K.Elements.Button({
            'type': 'submit',
            'buttonType':'primary',
            'block': true,
            'buttonText': 'Återställ lösenord'
        });
        this.resetButton = new K.Elements.Button({
            'type': 'button',
            'block': true,
            'buttonText': 'Avbryt'
        });
        this.inputUsername = new K.Elements.InputText({
            'placeholder': 'Fyll i ditt användarnamn  (vanligtvis e-postadress)',
            'className':'k-block',
            'icon':'user'
        });
        this.formContainer = new K.Elements.Form({
            name:'password-reset',
            id:'k-password-reset'
        })
        .preventDefault()
        .on('submit', () => this.submit());        
        this.formContainer.append([
            K.DOM.createElement('img',{'className':'k-logo','src':'/assets/logo.png'}),
            K.DOM.createElement('h1',{'text':'Återställ lösenord'}),
            this.inputUsername,
            K.DOM.createElement('hr', {'className':'k-thin k-large'}),
            this.submitButton,
            this.resetButton,
            this.errorContainer
        ]);
        K.Events.on(this.resetButton,'click',(e) => this.notify('abort'));
        return this.formContainer;
    }
    submit(){
        if(this.inputUsername.value === ''){
            this.errorHandler('Du måste fylla i ditt användarnamn');
            return false;
        }
        new K.Request.POST(K.Config.api.url+'/auth/password-reset',{
            username:this.inputUsername.value
        })
        .then(function(data){
            this.notify('success', data);
            /*
            Återställning av lösenord lyckades. 
             
            Vi har skickat ett e-postmeddelande till din e-postadress. 
            Kolla i skräpkorgen ifall meddelande inte har nått dig inom 5 minuter.
            */
        }.bind(this))
        .catch((err) => this.errorHandler(err));
    }
}
