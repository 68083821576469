import { RoutineModule } from '../../../Modules/Routine/Routine.mjs';

export class UsageList {
	constructor(Controller, args) {

		this.Controller = Controller;
        this.Container = new K.Elements.Div({'className':'k-routine-usage-list'});
		const eventHandler = new K.Observer(['abort']);
		this.on = eventHandler.listen;
		this.notify = eventHandler.notify;

		this.selectedRoutines = [];
	}
	errorHandler(response){
		let alertNotice = new K.Components.Alert({
			'type':'danger'
		});
        if(typeof(response.status_text) !== "undefined"){
		    alertNotice.appendChild(K.DOM.createElement('h3',{'text':response.status_text}));
        }else{
            alertNotice.appendChild(K.DOM.createElement('h3',{'text':response.toString()}));
        }
		this.errorContainer.appendChild(alertNotice);
	}
	
	
	renderTable() {
		return new Promise((resolve, reject) => {

			RoutineModule.getUsageList()
				.then((TableRows) => {		
					this.tableList.clear();			
					const TableHeaders = [
						'Kontonamn', 
						'Datamängder',
						'Kartor',
						'Vyer',
						'Användarnamn',
						'E-post',
						'Senast inloggad',
						'Inloggningen löper ut'
			
					];
					this.tableList.addHeaderRow(TableHeaders);
					
					if(TableRows.length === 0){
						if(!K.Utils.isElement(this.infoMessage.parentNode)){
							this.Content.append(this.infoMessage);
						}
						this.infoMessage.clear();
						this.infoMessage.append([
							K.DOM.createElement('h1',{'className':'k-text-light','text':'Data saknas'})
						]);
					}else{
						if(K.Utils.isElement(this.infoMessage.parentNode)){
							this.infoMessage.parentNode.removeChild(this.infoMessage);
						}
						TableRows.forEach((Row) => {
							this.tableList.addRow([
								Row.account_name,
								Row.num_datasets,
								Row.num_maps,
								Row.num_views,
								Row.username,
								Row.email,
								Row.last_login,
								Row.session_expires
							])
						});
					}
					resolve();

				})
				.catch(reject);
		});
	}
	

	reloadTable() {
		return new Promise(function (resolve, reject) {
			this.renderTable()
				.then(resolve)
				.catch(reject);
		}.bind(this));
	}
	
	destroy(){

	}
	render() {
		this.Container.clear();
		this.Top = new K.Elements.Div({'className':'k-routine-usage-list-top'}).appendTo(this.Container);
		this.Content = new K.Elements.Div({'className':'k-routine-usage-list-table'}).appendTo(this.Container);
		this.errorContainer = new K.Elements.Div({'className':'k-error-panel'});
		this.infoMessage = new K.Elements.Div({'className':'k-jumbo'});
		this.tableList = new K.Elements.Table();
		
		this.Top.append([
			K.DOM.createElement('div', { 'className': 'k-header' }).append([
				K.DOM.createElement('h1', {
					'children': [
						K.DOM.createElement('i', { 'className': 'fa fa-microchip' }),
						K.DOM.createTextNode('Rutiner')
					]
				})
			]),
			this.errorContainer
		]);
		
		
		this.Content.append(this.tableList);
		
		this.reloadTable();		

		return this.Container;
	}
}
