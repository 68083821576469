import { UserModule } from '../../../Modules/User/User.mjs';

export class ManageUser {
	constructor(Controller) {
		
		
		this.Controller = Controller;
        this.Container = new K.Elements.Div({'className':'k-user-manage'});
		const eventHandler = new K.Observer(['abort','finished']);
		this.on = eventHandler.listen;
		this.notify = eventHandler.notify;
		this.section = undefined;
		this.user = undefined;
		this.uuid = undefined;
		this.account_uuid = undefined;
		this.permissionNameTitles = {
			'maps':'Kartor',
			'datasets':'Datamängder',
			'views':'Vyer'
		};
		
	}
	errorHandler(response){
		let alertNotice = new K.Components.Alert({
			'type':'danger'
		});
		console.error(response);
        if(typeof(response.status_text) !== "undefined"){
		    alertNotice.appendChild(K.DOM.createElement('h3',{'text':response.status_text}));
        }else{
            alertNotice.appendChild(K.DOM.createElement('h3',{'text':response.toString()}));
        }
		this.errorContainer.appendChild(alertNotice);
	}
	editColumnName(thRow){

		let uuid = thRow.user.uuid;
		let anchorElement = thRow.querySelector('.column-header');
		let quickRename = new K.Components.QuickRenamer(anchorElement);
		quickRename.on('done', function(name){			
			UserModule.renameUserColumn(uuid, name)
				.then(function(column){
					anchorElement.textContent = name;
				}.bind(this))
				.catch((err) => this.errorHandler(err));
		}.bind(this));
	}

	destroy(){
		
	}
	
	updateUserPermissions(){
        
		return new Promise((resolve, reject) => {
			let Rows = this.permissionList.querySelectorAll('tbody tr');
			Rows.forEach((Row) => {
				let name = Row.dataset.name;
				let active = Row.querySelector('input[name="active"]').checked ? 1 : 0;
				let C = Row.querySelector('input[name="C"]').checked ? 1 : 0;
				let R = Row.querySelector('input[name="R"]').checked ? 1 : 0;
				let U = Row.querySelector('input[name="U"]').checked ? 1 : 0;
				let D = Row.querySelector('input[name="D"]').checked ? 1 : 0;
				let E = Row.querySelector('input[name="E"]').checked ? 1 : 0;

				let countries = [];
				K.Config.supported_countries.forEach((country_code) => {
					let selectedCountry = Row.querySelector('input[name="country"][value="'+country_code+'"]:checked');
					if(selectedCountry){
						countries.push(selectedCountry.value);
					}
				});
				
				
				new K.Request.POST(K.Config.api.url+'/update/user/'+this.uuid+'/permissions.json',{
					'name':name,
					'active':active,
					'countries':countries,
					'C':C,
					'R':R,
					'U':U,
					'D':D,
					'E':E
				})
					.then((user_permission) => {
						resolve();						
					})
					.catch((err) => reject(err));
			});

			resolve();
			
		});
    }
	updateUser(){
		
		
        this.errorContainer.clear();
        this.submitButton.disabled = true;
        this.cancelButton.disabled = true;
		let user_role = this.roleSelectorContainer.querySelector('input[name="user_role"]:checked');
		UserModule.updateUser(this.uuid, {
                'name':this.inputUserName.value,
                'email':this.inputEmail.value,
				'user_role':user_role.value,
                'active':this.enableUserToggle.checked ? 1 : 0,
                'date_start':this.userStartDateInput.value,
                'date_expires':this.userExpiresDateInput.value
            })
            .then((user) => {
				this.user = user;
				this.userNameElement.innerText = this.user.username;
				this.updateUserPermissions()
					.then(() => {
				  		this.openUserProperties();
					})
					.catch((err) => this.errorHandler(err));
            })
            .catch((err) => this.errorHandler(err));
        return false;
    }
	sendUserCredentials(){
		let dialog = new K.Components.Dialog({
			'title': "Skicka inloggingsuppgifter"
		});
		let acceptButton = new K.Elements.Button({
			'block': true,
			'buttonText': 'Ja'
		});
		let declineButton = new K.Elements.Button({
			'block': true,
			'buttonText': 'Nej'
		});

		dialog.append([
			new K.Elements.Div({'className':'k-stack'}).append([
				K.DOM.createElement('p', { 'className': 'k-text-bold k-text-center', 'text': 'Är du säker på att du vill skicka ett välkomstmail med inloggningsuppgifter till '+this.user.username+' ('+this.user.email+') ?' }),
				new K.Elements.Div({'className':'k-button-group'}).append([
					acceptButton,
					declineButton
				])
			])
		]);

		acceptButton.click(function () {
			UserModule.sendUserCredentials(this.uuid)
				.then(function (data) {
					dialog.close();
				}.bind(this))
				.catch((err) => this.errorHandler(err));
		}.bind(this));
		declineButton.click((e) => dialog.close());
		dialog.open();
	}
	openEditUser(){
		K.Router.navigate('User', '/account/'+this.account_uuid+'/user/'+this.uuid+'/edit');
	}
	renderEditUser(){
		this.errorContainer.clear();
		this.Content.clear();
        
        let listELement = new K.Elements.List();
        
        listELement.addChildNode(K.DOM.createElement('li',{'text':'Om du inte fyller i ett datumvärde i fältet "Aktivt t.o.m" och "Aktivt fr.o.m" så innebär det att användaren är aktiv tillsvidare. Det är alltså inget krav.'}));
        const InfoCol = new K.Elements.Col(4, {padded:true}).append([
            K.DOM.createElement('h3',{'text':'Hjälp'}),
            listELement
        ]);
        const FormCol = new K.Elements.Col(8, {padded:true});
        
        this.submitButton = new K.Elements.Button({
            'type': 'submit',
            'buttonType':'primary',
            'block': true,
            'disabled': false,
            'buttonText': 'Spara ändringar'
        });
        this.cancelButton = new K.Elements.Button({
            'type': 'reset',
            'buttonType':'default',
            'block': true,
            'buttonText': 'Avbryt'
        });
        this.manageUserForm = new K.Elements.Form({
            name:'user-create',
            id:'k-user-create-form'
        })
        .preventDefault()
        .on('submit', () => this.updateUser());
        
        this.inputUserName = new K.Elements.InputText({
            'placeholder': 'Fyll i önskat användarnamn',
            'className':'k-block',
			'value':this.user.username
        });
		this.inputEmail = new K.Elements.InputText({
            'placeholder': 'E-post till användaren',
            'className':'k-block',
			'value':this.user.email
        });
		this.inputUserName.on('submit', () => this.updateUser());

		this.roleSelectorContainer = new K.Elements.Div({'className':'k-row'});

		K.Config.supported_roles.map((role) => {
			let radioButton = new K.Elements.RadioButton({'value':role,'name':'user_role'});
			radioButton.check(this.user.user_role === role);
			let label;
			switch(role){
				case "superadmin":
						label = K.DOM.createElement('label',{'children':[
							radioButton,
							K.DOM.createElement('span',{'className':'k-role-title','text':'Superadmin'})
						]});
						
				break;
				case "admin":
						label = K.DOM.createElement('label',{'children':[
							radioButton,
							K.DOM.createElement('span',{'className':'k-role-title','text':'Admin'})
						]});
						
				break;
				case "user":
					label = K.DOM.createElement('label',{'children':[
						radioButton,
						K.DOM.createElement('span',{'className':'k-role-title','text':'Användare'})
					]});
				break;				
			}			
			this.roleSelectorContainer.append(new K.Elements.Col(4, {padded:false}).append(label));
			
		});

        
        this.enableUserToggle = new K.Elements.CheckboxSlider({
			'text':'Aktiv användare',
			'checked':this.user.active ? true : false
		});

        this.userStartDateInput = new K.Elements.Input({
            'type': 'date',
            'className':'k-block',
			'value':this.user.date_start
        });
        this.userExpiresDateInput = new K.Elements.Input({
            'type': 'date',
            'className':'k-block',
			'value':this.user.date_expires
        });
		
        this.inputUserName.on('change', () => {
            this.errorContainer.clear();
            this.submitButton.disabled = this.inputUserName.value.length === 0;
            this.cancelButton.disabled = false;
        });

		

        this.manageUserForm.append([
            new K.Elements.Div({'className':'k-row'}).append([
                new K.Elements.Col(12, {padded:true}).append([
                    K.DOM.createElement('h2',{'text':'E-post'}),
                    this.inputEmail,
					K.DOM.createElement('hr', {'className':'k-thin k-large'}),
					K.DOM.createElement('h2',{'text':'Användarnamn'}),
                    this.inputUserName,
					K.DOM.createElement('hr', {'className':'k-thin k-large'}),
					K.DOM.createElement('h2',{'text':'Roll'}),
					this.roleSelectorContainer,				
					K.DOM.createElement('hr', {'className':'k-thin k-large'}),
                    K.DOM.createElement('h2',{'text':'Status'}),
                    this.enableUserToggle,
					K.DOM.createElement('hr', {'className':'k-thin k-large'}),
                    K.DOM.createElement('h3',{'text':'Aktivt fr.o.m'}),
                    this.userStartDateInput,
					K.DOM.createElement('hr', {'className':'k-thin k-large'}),
                    K.DOM.createElement('h3',{'text':'Aktivt t.o.m'}),
                    this.userExpiresDateInput,
					K.DOM.createElement('hr', {'className':'k-thin k-large'}),
					K.DOM.createElement('h3',{'text':'Rättigheter'}),
					this.permissionList
                    
                ])
            ]),
			this.errorContainer,
            new K.Elements.Div({'className':'k-row'}).append([
                new K.Elements.Col(6, {padded:true}).append([this.submitButton]),
                new K.Elements.Col(6, {padded:true}).append([this.cancelButton])
            ])
        ]);
		this.renderPermissionEditorList();
        FormCol.append(this.manageUserForm);
        this.Content.append([
            K.DOM.createElement('div',{'className':'k-header'}).append([
                K.DOM.createElement('h1',{'children':[
                    K.DOM.createElement('i',{'className':'fa fa-briefcase'}),
                    K.DOM.createTextNode('Redigera användare')
                ]})
            ])            
        ]);
        this.Content.append([
            new K.Elements.Div({'className':'k-row'}).append([
                FormCol,
                InfoCol
            ])
            
        ]);
        
        this.cancelButton.on('click',() => this.renderUserProperties());

    }
	renderPermissionEditorList(){
		
		this.permissionList.clear();
		const TableHeaders = [
			'Namn', 
			'Aktiv',
			'Skapa',
			'Läsa',
			'Uppdatera',
			'Radera',
			'Exportera',
			K.DOM.createElement('i',{'className':'k-flag k-flag-s k-flag-se'}), 			
			K.DOM.createElement('i',{'className':'k-flag k-flag-s k-flag-no'}),
			K.DOM.createElement('i',{'className':'k-flag k-flag-s k-flag-dk'}),
			K.DOM.createElement('i',{'className':'k-flag k-flag-s k-flag-fi'})
		]
		this.permissionList.addHeaderRow(TableHeaders);
		this.user_permissions.forEach((Row) => {
			let active = new K.Elements.CheckboxSlider({
				'name':'active',
				'checked':Row.active === 1,
				'rounded':true
	
			});
			active.on('change', () => {				
			});

			let tableRow = this.permissionList.addRow([
				this.permissionNameTitles[Row.name], 
				active, 
				new K.Elements.Checkbox({'value':'1','name':'C','checked':Row.C ? true : false}),
				new K.Elements.Checkbox({'value':'1','name':'R','checked':Row.R ? true : false}),
				new K.Elements.Checkbox({'value':'1','name':'U','checked':Row.U ? true : false}),
				new K.Elements.Checkbox({'value':'1','name':'D','checked':Row.D ? true : false}),
				new K.Elements.Checkbox({'value':'1','name':'E','checked':Row.E ? true : false}),
				new K.Elements.Checkbox({'value':'se','name':'country','checked':Row.countries.indexOf('se') !== -1}),
				new K.Elements.Checkbox({'value':'no','name':'country','checked':Row.countries.indexOf('no') !== -1}),
				new K.Elements.Checkbox({'value':'dk','name':'country','checked':Row.countries.indexOf('dk') !== -1}),
				new K.Elements.Checkbox({'value':'fi','name':'country','checked':Row.countries.indexOf('fi') !== -1})
			]);
			tableRow.dataset.name = Row.name;
			
		});

	}
	

	openUserProperties(){
		K.Router.navigate('User', '/account/'+this.account_uuid+'/user/'+this.uuid+'/properties');
	}
	renderUserProperties(){
		this.section = 'properties';
		this.Content.clear();
		this.userPropertiesButton.classList.add('k-button-selected');
		this.eventLogButton.classList.remove('k-button-selected');
		
		let editButton = new K.Elements.Button({
			'block': true,
			'buttonType':'plain',
			'buttonText': 'Redigera'
		});
		editButton.click(this.openEditUser.bind(this));

		let sendCredentialsButton = new K.Elements.Button({
			'block': true,
			'buttonType':'plain',
			'buttonText': 'Skicka inloggningsuppgifter'
		});
		sendCredentialsButton.click(this.sendUserCredentials.bind(this));

		this.Content.append([
			new K.Elements.Div({'className':'k-row'}).append([
				new K.Elements.Col(6, { padded: true }).append(editButton),
				new K.Elements.Col(6, { padded: true }).append(sendCredentialsButton)
			]),
			new K.Elements.Div({'className':'k-row'}).append([
				new K.Elements.Col(12, {padded:true}).append([
					K.DOM.createElement('h2',{'text':'E-post'}),
					K.DOM.createElement('p',{'text':this.user.email}),
					K.DOM.createElement('hr', {'className':'k-thin k-large'}),
					K.DOM.createElement('h2',{'text':'Användarnamn'}),
					K.DOM.createElement('p',{'text':this.user.username}),
					K.DOM.createElement('hr', {'className':'k-thin k-large'}),
					K.DOM.createElement('h2',{'text':'Roll'}),
					K.DOM.createElement('p',{'text':this.user.user_role}),
					K.DOM.createElement('hr', {'className':'k-thin k-large'}),
					K.DOM.createElement('h2',{'text':'Status'}),
					K.DOM.createElement('p',{'text':this.user.active ? 'Aktiv användare':'Inaktiv användare'}),
					K.DOM.createElement('hr', {'className':'k-thin k-large'}),
					K.DOM.createElement('h3',{'text':'Aktivt fr.o.m'}),
					K.DOM.createElement('p',{'text':this.user.date_start}),
					K.DOM.createElement('hr', {'className':'k-thin k-large'}),
					K.DOM.createElement('h3',{'text':'Aktivt t.o.m'}),
					K.DOM.createElement('p',{'text':this.user.date_expires}),
					K.DOM.createElement('hr', {'className':'k-thin k-large'}),
					K.DOM.createElement('h3',{'text':'Rättigheter'}),
					this.permissionList
				])				
			])
		]);
		this.renderPermissionList();
		
	}
	renderPermissionList(){
		
		this.permissionList.clear();
		let permissionTitles = {
			'C':'Skapa',
			'R':'Läsa',
			'U':'Uppdatera',
			'D':'Radera',
			'E':'Exportera'
		};

		const TableHeaders = [
			'Namn', 
			'Aktiv',
			'Skapa',
			'Läsa',
			'Uppdatera',
			'Radera',
			'Exportera',
			K.DOM.createElement('i',{'className':'k-flag k-flag-s k-flag-se'}), 			
			K.DOM.createElement('i',{'className':'k-flag k-flag-s k-flag-no'}),
			K.DOM.createElement('i',{'className':'k-flag k-flag-s k-flag-dk'}),
			K.DOM.createElement('i',{'className':'k-flag k-flag-s k-flag-fi'})
		];
		this.permissionList.addHeaderRow(TableHeaders);
		
		this.user_permissions.forEach((Row) => {
			this.permissionList.addRow([
				this.permissionNameTitles[Row.name], 
				Row.active === 1 ? 'Ja':'Nej', 
				Row.C ? K.DOM.createElement('i',{'className':'fa fa-check k-color-success-700'}) : K.DOM.createElement('i',{'className':'fa fa-times k-color-danger-700'}), 
				Row.R ? K.DOM.createElement('i',{'className':'fa fa-check k-color-success-700'}) : K.DOM.createElement('i',{'className':'fa fa-times k-color-danger-700'}), 
				Row.U ? K.DOM.createElement('i',{'className':'fa fa-check k-color-success-700'}) : K.DOM.createElement('i',{'className':'fa fa-times k-color-danger-700'}), 
				Row.D ? K.DOM.createElement('i',{'className':'fa fa-check k-color-success-700'}) : K.DOM.createElement('i',{'className':'fa fa-times k-color-danger-700'}), 
				Row.E ? K.DOM.createElement('i',{'className':'fa fa-check k-color-success-700'}) : K.DOM.createElement('i',{'className':'fa fa-times k-color-danger-700'}), 
				Row.countries.indexOf('se') !== -1 ? K.DOM.createElement('i',{'className':'fa fa-check k-color-success-700'}) : K.DOM.createElement('i',{'className':'fa fa-times k-color-danger-700'}),
				Row.countries.indexOf('no') !== -1 ? K.DOM.createElement('i',{'className':'fa fa-check k-color-success-700'}) : K.DOM.createElement('i',{'className':'fa fa-times k-color-danger-700'}),
				Row.countries.indexOf('dk') !== -1 ? K.DOM.createElement('i',{'className':'fa fa-check k-color-success-700'}) : K.DOM.createElement('i',{'className':'fa fa-times k-color-danger-700'}),
				Row.countries.indexOf('fi') !== -1 ? K.DOM.createElement('i',{'className':'fa fa-check k-color-success-700'}) : K.DOM.createElement('i',{'className':'fa fa-times k-color-danger-700'})
			]);
			
		});

	}
	

	openEventLog(){
		K.Router.navigate('User', '/account/'+this.account_uuid+'/user/'+this.uuid+'/events');
	}
	
	renderEventLog(){
		this.section = 'events';
		this.Content.clear();
		this.userPropertiesButton.classList.remove('k-button-selected');
		this.eventLogButton.classList.add('k-button-selected');
		
		this.Content.append([
			new K.Elements.Div({'className':'k-row'}).append([
				new K.Elements.Col(12, {padded:true}).append([
					K.DOM.createElement('h2',{'text':'Händelser'}),
					K.DOM.createElement('p',{'text':'Lista på inloggningar, exporter och andra aktiviteter som skett'})
				])
			])
		]);
		
	}
	openPermissionList(){
		K.Router.navigate('User', '/account/'+this.account_uuid+'/user/'+this.uuid+'/permissions');
	}
	
	
	
	destroy(){
		
	}
	render(account_uuid, user_uuid, params){
		this.account_uuid = account_uuid;
		this.uuid = user_uuid;
		params = params ?? {};
		K.Utils.forOwn(params, function(val, key){
			this[key] = val;
		}.bind(this));
		
		this.Container.clear();
		this.Top = new K.Elements.Div({'className':'k-user-manage-top'}).appendTo(this.Container);
		this.Content = new K.Elements.Div({'className':'k-user-manage-content'}).appendTo(this.Container);
		this.permissionList = new K.Elements.Table();
		this.errorContainer = new K.Elements.Div({'className':'k-error-panel'});
		
		
		const backButton = new K.Elements.Button({
			'type': 'button',
			'buttonType':'plain',
			'block': false,
			'buttonText': '❮'
		});
		this.userPropertiesButton = new K.Elements.Button({
			'type': 'button',
			'buttonType':'plain',
			'block': false,
			'icon':'briefcase',
			'buttonText': 'Egenskaper för användare'
		});
		this.eventLogButton = new K.Elements.Button({
			'type': 'button',
			'buttonType':'plain',
			'block': false,
			'icon':'file-contract',
			'buttonText': 'Händelser'
		});
		this.userNameElement = K.DOM.createElement('span',{'id':'k-user-manage-user-name','text':''});
		UserModule.getUser(this.uuid)
			.then((user) => {
				this.user = user;
				this.userNameElement.innerText = this.user.username;
				this.Top.append([
					K.DOM.createElement('div',{'className':'k-user-manage-user-title'}).append([
						K.DOM.createElement('h1',{'children':[
							backButton,
							this.userNameElement					
						]})
					]),
					K.DOM.createElement('div',{'className':'k-option-panel'}).append([
						K.DOM.createElement('div',{'className':'k-inline-block','children':[this.userPropertiesButton, this.eventLogButton]})
					])				
					
				]);
				
				UserModule.getUserPermissions(this.uuid)
					.then((permissions)=>{
						this.user_permissions = permissions;
						switch(this.section){
							case "edit":
								this.renderEditUser();
							break;
							case "events":
								this.renderEventLog();
							break;
							default:
								this.renderUserProperties();
						
						}
					})
					.catch((err) => this.errorHandler(err));
			})
			.catch((err) => this.errorHandler(err));
		
		backButton.on('click',(e) => this.notify('finished'));
		this.userPropertiesButton.on('click', () => this.openUserProperties());
		this.eventLogButton.on('click', () => this.openEventLog());
		return this.Container;

	}
}
