export class NewPasswordForm {
    constructor(args) {
        const eventHandler = new K.Observer(['success','error','abort']);
		this.on = eventHandler.listen;
		this.notify = eventHandler.notify;
        this.token = undefined;
    }
    errorHandler(response){
		let alertNotice = new K.Components.Alert({
			'type':'danger'
		});
		this.errorContainer.clear();
        if(typeof(response.status_text) !== "undefined"){
		    alertNotice.appendChild(K.DOM.createElement('h3',{'text':response.status_text}));
        }else{
            alertNotice.appendChild(K.DOM.createElement('h3',{'text':response.toString()}));
        }
		this.errorContainer.appendChild(alertNotice);
	}
    render(token){
        this.token = token;
        this.errorContainer = new K.Elements.Div({'className':'k-error-panel'});
        this.submitButton = new K.Elements.Button({
            'type': 'submit',
            'buttonType':'primary',
            'block': true,
            'buttonText': 'Återställ lösenord'
        });
        this.resetButton = new K.Elements.Button({
            'type': 'button',
            'block': true,
            'buttonText': 'Avbryt'
        });
        this.inputPassword = new K.Elements.InputPassword({
            'placeholder': 'Lösenord',
            'className':'k-block',
            'icon':'key'
        });
        this.inputPasswordAgain = new K.Elements.InputPassword({
            'placeholder': 'Lösenord (igen)',
            'className':'k-block',
            'icon':'key'
        });
        
        this.formContainer = new K.Elements.Form({
            name:'new-password',
            id:'k-new-password'
        })
        .preventDefault()
        .on('submit', () => this.submit());        
        K.Events.on(this.resetButton,'click',(e) => this.notify('abort'));
        this.formContainer.append([
            K.DOM.createElement('img',{'className':'k-logo','src':'/assets/logo.png'}),
            K.DOM.createElement('h1',{'text':'Välj nytt lösenord'}),
            this.inputPassword,
            this.inputPasswordAgain,
            K.DOM.createElement('hr', {'className':'k-thin k-large'}),
            this.submitButton,
            this.resetButton,
            this.errorContainer
        ]);
        return this.formContainer;
    }
    submit(){
        if(this.inputPassword.value === ''){
            this.errorHandler('Du måste fylla i ett lösenord');
            return false;
        }
        if(this.inputPasswordAgain.value === ''){
            this.errorHandler('Du har glömt att fylla i lösenordet en andra gång');
            return false;
        }
        if(this.inputPassword.value !== this.inputPasswordAgain.value){
            this.errorHandler('Du fyllde inte i samma lösenord i båda fälten. Försök igen.');
            return false;
        }
        if(this.inputPassword.value.length <= 5){
            this.errorHandler('Lösenordet måste vara minst 6 tecken långt.');
            return false;
        }
        new K.Request.POST(K.Config.api.url+'/auth/set-password',{
            password:this.inputPassword.value,
            password_again:this.inputPasswordAgain.value,
            token:this.token
        })
        .then(function(data){
            this.notify('success', data);
            /*
            Återställning av lösenord lyckades. 
             
            Vi har skickat ett e-postmeddelande till din e-postadress. 
            Kolla i skräpkorgen ifall meddelande inte har nått dig inom 5 minuter.
            */
        }.bind(this))
        .catch((err) => this.errorHandler(err));

    }
}
