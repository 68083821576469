import { UserModule } from '../../../Modules/User/User.mjs';


export class UserList {
	constructor(Controller, args) {

		this.Controller = Controller;
        this.Container = new K.Elements.Div({'className':'k-user-list'});
		const eventHandler = new K.Observer(['abort']);
		this.on = eventHandler.listen;
		this.notify = eventHandler.notify;

		this.selectedUsers = [];

	}
	errorHandler(response){
		let alertNotice = new K.Components.Alert({
			'type':'danger'
		});
		console.error(response);
        if(typeof(response.status_text) !== "undefined"){
		    alertNotice.appendChild(K.DOM.createElement('h3',{'text':response.status_text}));
        }else{
            alertNotice.appendChild(K.DOM.createElement('h3',{'text':response.toString()}));
        }
		this.errorContainer.appendChild(alertNotice);
	}
	
	updateTableRow(Row, existingRowElement){
		let statusElement = existingRowElement.querySelector('.k-users-list-user-row-status');
		if(statusElement){
			let currentStatus = statusElement.user.status;
			if(currentStatus.toString() !== Row.status.toString()){

				let statusLabel = K.DOM.createElement('span',{'className':'k-users-list-user-row-status','data-status':Row.status});
				switch(Row.status){
					case 1:
						statusLabel.append([
							K.DOM.createElement('i', {'className':'fa fa-check k-mr-s'}),
							K.DOM.createTextNode('OK')
						]);
					break;
					case 99:
						statusLabel.append([
							K.DOM.createElement('i', {'className':'fa fa-triangle-exclamation k-text-error k-mr-s'}),
							K.DOM.createTextNode('misslyckades')
						]);
					break;
					case 2:
						statusLabel.append([
							K.DOM.createElement('i', {'className':'fas fa-circle-notch fa-spin k-mr-s'}),
							K.DOM.createTextNode('Importerar...')
						]);
					break;
					default:
						statusLabel.append([
							K.DOM.createElement('i', {'className':'fa fa-clock k-mr-s'}),
							K.DOM.createTextNode('Väntar på analys')
						]);
				}
				statusElement.parentNode.replaceChild(statusLabel, statusElement);
			}
		}
	}
	renderTableRow(Row){
		let checkbox = new K.Elements.Checkbox({
			value: Row.uuid
		});
		let userNameElement = K.DOM.createElement('a', { 'href': 'javascript:;', 'data-uuid': Row.uuid, 'text': Row.username });
		let options = K.DOM.createElement('span', { 'className': 'k-icon-button', 'children': [K.DOM.createElement('i', { 'className': 'fa fw fa-ellipsis-v' })] });
		let dropdown = new K.Components.Dropdown(options, {
			'dropdownOptions': [
				K.DOM.createElement('span', {
					'data-action': 'remove', 'children': [
						K.DOM.createElement('i', { 'className': 'fa fa-times'}),
						K.DOM.createTextNode('Ta bort')
					]
				})
			]
		});
		dropdown.dataset.uuid = Row.uuid;
		let tableRow = this.tableList.addRow([checkbox, userNameElement, Row.user_role, Row.active === 1 ? 'Ja':'Nej', Row.date_start, Row.date_expires, Row.date_created, options]);
		tableRow.dataset.uuid = Row.uuid;
		K.Events.on(userNameElement, 'click', this.Controller.openUser.bind(this.Controller, Row.uuid));
		dropdown.on('dropdownItemClick', function (dropdown, data) {
			switch (data.action) {
				case "remove":

					let dialog = new K.Components.Dialog({
						'title': "Ta bort användare"
					});
					let acceptButton = new K.Elements.Button({
						'block': true,
						'buttonText': 'Ja'
					});
					let declineButton = new K.Elements.Button({
						'block': true,
						'buttonText': 'Nej'
					});
		
					dialog.append([
						new K.Elements.Div({'className':'k-stack'}).append([
							K.DOM.createElement('p', { 'className': 'k-text-bold k-text-center', 'text': 'Är du säker på att du vill ta bort denna användare?' }),
							K.DOM.createElement('p', { 'className': 'k-text-center', 'text': 'Detta beslut går inte att ångra.' }),
							new K.Elements.Div({'className':'k-button-group'}).append([
								acceptButton,
								declineButton
							])
						])
					]);
		
					acceptButton.click(function () {
						UserModule.removeUser(dropdown.dataset.uuid)
							.then(function (user) {
								tableRow.parentNode.removeChild(tableRow);
								dialog.close();
							}.bind(this))
							.catch((err) => this.errorHandler(err));
					}.bind(this));
					declineButton.click((e) => dialog.close());
					dialog.open();

					
					break;
			}
		}.bind(this, dropdown));
		checkbox.on('click', function (el, is_checked) {
			if (!is_checked) {
				this.selectAllCheckbox.check(false);
				let position = this.selectedUsers.indexOf(el.value);
				if (position !== -1) {
					this.selectedUsers.splice(position, 1);
				}
			} else {
				let position = this.selectedUsers.indexOf(el.value);
				if (position === -1) {
					this.selectedUsers.push(el.value);
				}
			}
			this.selectedUsers.length > 0 ? this.removeSelectedButton.classList.remove('k-hidden') : this.removeSelectedButton.classList.add('k-hidden');
		}.bind(this, checkbox));
		this.checkboxesInTable.push(checkbox);
	}
	renderTable() {
		return new Promise(function (resolve, reject) {
			new K.Request.GET(K.Config.api.url + '/list/users', {'account_uuid':this.account_uuid})
				.then(function (TableRows) {
					if(TableRows.length === 0){
						if(!K.Utils.isElement(this.infoMessage.parentNode)){
							this.Content.append(this.infoMessage);
						}
						this.infoMessage.clear();
						this.infoMessage.append([
							K.DOM.createElement('h1',{'className':'k-text-light','text':'Klicka på "Skapa användare" för att lägga till en ny användare'})
						]);
					}else{
						if(K.Utils.isElement(this.infoMessage.parentNode)){
							this.infoMessage.parentNode.removeChild(this.infoMessage);
						}
						TableRows.forEach(function (Row) {
							let existingRowElement = this.tableList.querySelector('tr[data-uuid="'+Row.uuid+'"]');
							if(existingRowElement){
								this.updateTableRow(Row, existingRowElement);
							}else{
								this.renderTableRow(Row);
							}
							
						}.bind(this));
					}
					resolve();

				}.bind(this))
				.catch(reject);
		}.bind(this));
	}
	

	reloadTable() {
		return new Promise(function (resolve, reject) {
			this.renderTable()
				.then(resolve)
				.catch(reject);
		}.bind(this));
	}
	removeSelected() {
		if (this.selectedUsers.length > 0) {
			let dialog = new K.Components.Dialog({
				'title': "Ta bort markerade användare"
			});
			let acceptButton = new K.Elements.Button({
				'block': true,
				'buttonText': 'Ja'
			});
			let declineButton = new K.Elements.Button({
				'block': true,
				'buttonText': 'Nej'
			});

			dialog.append([
				new K.Elements.Div({'className':'k-stack'}).append([
					K.DOM.createElement('p', { 'className': 'k-text-bold k-text-center', 'text': 'Är du säker på att du vill ta bort markerade användare?' }),
					K.DOM.createElement('p', { 'className': 'k-text-center', 'text': 'Detta beslut går inte att ångra.' }),
					new K.Elements.Div({'className':'k-button-group'}).append([
						acceptButton,
						declineButton
					])
				])
			]);

			acceptButton.click(function () {
				UserModule.removeUsers(this.selectedUsers)
					.then(function (users_removed) {
						this.removeSelectedButton.classList.add('k-hidden');
						this.selectedUsers.forEach(function(uuid){
							let existingRowElement = this.tableList.querySelector('tr[data-uuid="'+uuid+'"]');
							if(existingRowElement && existingRowElement.parentNode){
								existingRowElement.parentNode.removeChild(existingRowElement);
							}
							this.selectAllCheckbox.check(false);
							
						}.bind(this));
						this.reloadTable()
							.then((e) => dialog.close());
					}.bind(this))
					.catch((err) => this.errorHandler(err));
			}.bind(this));
			declineButton.click((e) => dialog.close());
			dialog.open();
		}
	}

	destroy(){

	}

	createUser(){
		let dialog = new K.Components.Dialog({
			'title': "Ny användare"
		});
		let continueButton = new K.Elements.Button({
			'block': true,
			'buttonType':'primary',
			'buttonText': 'Skapa användare'
		});
		let abortButton = new K.Elements.Button({
			'block': true,
			'buttonText': 'Avbryt'
		});
		let usernameInput = new K.Elements.InputText({
			'block': true,
			'placeholder': 'Välj ett användarnamn  (vanligtvis e-postadress)'
		});
		let emailInput = new K.Elements.InputText({
			'block': true,
			'placeholder': 'E-post till användaren'
		});
		let enableUserToggle = new K.Elements.CheckboxSlider({
			'text':'Aktiv användare',
			'checked':true
		});
        let userStartDateInput = new K.Elements.Input({
            'type': 'date',
            'className':'k-block'
        });
        let userExpiresDateInput = new K.Elements.Input({
            'type': 'date',
            'className':'k-block'
        });
		
		this.roleSelectorContainer = new K.Elements.Div({'className':'k-row'});

		K.Config.supported_roles.map((role) => {
			let radioButton = new K.Elements.RadioButton({'value':role,'name':'user_role'});
			let label;
			switch(role){
				case "superadmin":
						label = K.DOM.createElement('label',{'children':[
							radioButton,
							K.DOM.createElement('span',{'className':'k-role-title','text':'Superadmin'})
						]});
						
				break;
				case "admin":
						radioButton.check(true);
						label = K.DOM.createElement('label',{'children':[
							radioButton,
							K.DOM.createElement('span',{'className':'k-role-title','text':'Admin'})
						]});
						
				break;
				case "user":
					label = K.DOM.createElement('label',{'children':[
						radioButton,
						K.DOM.createElement('span',{'className':'k-role-title','text':'Användare'})
					]});
				break;				
			}			
			this.roleSelectorContainer.append(new K.Elements.Col(4, {padded:false}).append(label));
			
		});

		dialog.append([
			new K.Elements.Div({'className':'k-stack'}).append([
				usernameInput,
				emailInput,
				K.DOM.createElement('hr', {'className':'k-thin k-large'}),
				K.DOM.createElement('h2',{'text':'Roll'}),
				this.roleSelectorContainer,
				K.DOM.createElement('hr', {'className':'k-thin k-large'}),
				K.DOM.createElement('p', { 'className': 'k-text-bold', 'text': 'Status:' }),
				enableUserToggle,
				K.DOM.createElement('hr', {'className':'k-thin k-large'}),
				new K.Elements.Div({'className':'k-row'}).append([
					new K.Elements.Col(6, {padded:false}).append([
						K.DOM.createElement('p', { 'className': 'k-text-bold', 'text': 'Aktiv fr.o.m:' }),
						userStartDateInput
					]),
					new K.Elements.Col(6, {padded:false}).append([
						K.DOM.createElement('p', { 'className': 'k-text-bold', 'text': 'Aktiv t.o.m:' }),
						userExpiresDateInput
					])
				]),
				K.DOM.createElement('hr', {'className':'k-thin k-large'}),
				
				new K.Elements.Div({'className':'k-button-group'}).append([
					continueButton,
					abortButton
				])
			])
		]);
		const submit = function () {
			let username = usernameInput.value;
			let email = emailInput.value;
			if(username.length === 0){
				let alertNotice = new K.Components.Alert({'type':'danger'});
				alertNotice.appendChild(K.DOM.createElement('h3',{'text':'Du måste välja ett användarnamn först.'}));
				dialog.append(alertNotice);
				return;
			}
			let user_role = this.roleSelectorContainer.querySelector('input[name="user_role"]:checked');

			UserModule.createUser({
				account_uuid:this.account_uuid,
				username:username,
				email:email,
				user_role:user_role.value??'admin',
				date_start:userStartDateInput.value,
				date_expires:userExpiresDateInput.value
			})
			.then(function (user) {
				dialog.close();
				K.Router.navigate('Account', '/account/'+this.account_uuid+'/users');
			}.bind(this))
			.catch(function(data){
				let alertNotice = new K.Components.Alert({'type':'danger'});
				alertNotice.appendChild(K.DOM.createElement('h3',{'text':data.status_text}));
				dialog.append(alertNotice);
				
			}.bind(this));            
		};
		usernameInput.on('submit',submit.bind(this));
		continueButton.click(submit.bind(this));
		abortButton.click((e) => dialog.close());
		dialog.open();

    }
	render(account_uuid) {
		this.account_uuid = account_uuid;
		this.Container.clear();
		this.Top = new K.Elements.Div({'className':'k-user-list-top'}).appendTo(this.Container);
		this.Content = new K.Elements.Div({'className':'k-user-list-table'}).appendTo(this.Container);
		this.errorContainer = new K.Elements.Div({'className':'k-error-panel'});
		this.infoMessage = new K.Elements.Div({'className':'k-jumbo'});
		this.tableList = new K.Elements.Table();
		this.selectAllCheckbox = new K.Elements.Checkbox();
		this.checkboxesInTable = [];
		
		this.createUserButton = new K.Elements.Button({
			'type': 'button',
			'buttonType': 'primary',
			'block': false,
			'buttonText': 'Lägg till användare'
		});
		this.removeSelectedButton = new K.Elements.Button({
			'type': 'button',
			'buttonType': 'danger',
			'block': false,
			'className':'k-mr-m',
			'buttonText': 'Ta bort markerade'
		});
		this.removeSelectedButton.classList.add('k-hidden');

		this.Top.append([
			K.DOM.createElement('div', { 'className': 'k-header' }).append([
				K.DOM.createElement('h2', {
					'children': [
						K.DOM.createElement('i', { 'className': 'fa fa-users' }),
						K.DOM.createTextNode('Användare')
					]
				})
			]),
			this.errorContainer,
			K.DOM.createElement('div', { 'className': 'k-option-panel' }).append([
				this.removeSelectedButton,
				this.createUserButton
				
			])
		]);
		
		this.selectAllCheckbox.on('click', function (is_checked) {
			if (!is_checked) {
				this.selectedUsers = [];
			}
			this.checkboxesInTable.forEach(function (checkbox) {
				checkbox.checked = is_checked;
				if (is_checked) {
					this.selectedUsers.push(checkbox.value);
				}
			}.bind(this));
			this.selectedUsers.length > 0 ? this.removeSelectedButton.classList.remove('k-hidden') : this.removeSelectedButton.classList.add('k-hidden');
		}.bind(this));
		
		const TableHeaders = [this.selectAllCheckbox, 'Namn', 'Roll', 'Aktiv','Aktiv fr.o.m', 'Aktiv t.o.m', 'Senast ändrad', ' '];
		this.tableList.addHeaderRow(TableHeaders);
		
		this.Content.append(this.tableList);
		
		this.reloadTable();		

		this.createUserButton.click(this.createUser.bind(this));
		this.removeSelectedButton.click(this.removeSelected.bind(this));

		return this.Container;
	}
}
