import { TopBar } from '../Partials/TopBar/TopBar.mjs';
import { Dashboard } from '../App/Dashboard/Dashboard.mjs';

import { Login } from './Login.mjs';

import { Routines } from '../App/Routines/Routines.mjs';
import { Accounts } from '../App/Accounts/Accounts.mjs';
import { ManageAccount } from '../App/Accounts/ManageAccount.mjs';

import { Users } from '../App/Users/Users.mjs';

import { Page404 } from '../App/Misc/Page404.mjs';


export class Main {
	constructor(App) {
		const eventHandler = new K.Observer(['logout']);
		this.on = eventHandler.listen;
		this.notify = eventHandler.notify;
		this.view = undefined;

		this.loginInstance = undefined;
		this.Page404Instance = undefined;

		this.AccountsInstance = new Accounts(this);
		this.RoutinesInstance = new Routines(this);
				
		this.UsersInstance = new Users(this);
		this.ManageUserInstance = undefined;
		
	}
	errorHandler(response){
		console.log(response);
	}
	renderHome(){
		this.renderAppInterface();
		//K.Router.navigate('Dashboard', '/');
		this.DashboardInstance = this.DashboardInstance || new Dashboard();
		this.main.clear().append(this.DashboardInstance.render());
	}
	renderRoutines(){
		this.renderAppInterface();		
		this.main.clear().append(this.RoutinesInstance.renderUsageList());
	}
	renderAccounts(){
		this.renderAppInterface();		
		let action = K.Router.getRequestParameter('action');
			
		switch(action) {
			case "create":
				this.main.clear().append(this.AccountsInstance.renderCreateAccount());
			break;
			default:
				//K.Router.navigate('Konton', '/accounts');
				this.main.clear().append(this.AccountsInstance.renderAccountList());
		
		} 
	}
	renderAccount(account_uuid, data){
		this.renderAppInterface();
		if(typeof(this.ManageAccountInstance) === "undefined"){
			this.ManageAccountInstance = new ManageAccount(this);
			this.ManageAccountInstance.on('abort', function(){
				//K.Router.navigate('Konton', '/accounts');
				this.render();
			}.bind(this));
			this.ManageAccountInstance.on('finished', () => this.renderAccounts());
		}
		this.main.clear().append(this.AccountsInstance.renderManageAccount(account_uuid, data));
	}
	renderUser(account_uuid, user_uuid, data){
		this.renderAppInterface();
		this.UsersInstance = this.UsersInstance || new Users(this);
		this.main.clear().append(this.UsersInstance.renderManageUser(account_uuid, user_uuid, data));
		
	}
	renderLogin(){
		
		this.renderAppContainer();
		this.loginInstance = new Login();
		this.loginInstance.render().appendTo(this.appContainer);
		this.loginInstance.on('login', () => {
			K.Router.navigate('Dashboard','/');
			//this.render();
		});
	}
	render404(){
		this.renderAppContainer();
		this.Page404Instance = new Page404();
		this.Page404Instance.render().appendTo(this.appContainer);
		this.Page404Instance.on('back', () => {
			history.back() ?? this.renderLogin();
		});
	}
	logout(){
		K.Cookie.unset('token');
		this.notify('logout');				
		K.Router.navigate('Logga in', '/login');
		//this.renderLogin();		
	}
	parseRequest(){
		if (K.Cookie.isset('token')) {
			let paths = K.Router.getRequest();
			let dirs = K.Router.getDirs();
			let filename = K.Router.getRequestFileName();
			let query = K.Router.getRequestParameters();
			this.view = paths[0];
			let baseDir = dirs[0] ?? null;
			let action = typeof(query) !== 'undefined' ? (query.action == null) : null;
			if(dirs.length > 0){
				switch(baseDir){
					case 'accounts':
						this.renderAccounts();
					break;
					case 'routines':
						this.renderRoutines();
					break;
					case 'account':
						let account_uuid = K.Router.getDir(1);
						let section = K.Router.getDir(2);
						switch(section){
							case "users":
								this.renderAccount(account_uuid, {
									section:section
								});
							break;
							case "user":
								let user_uuid = K.Router.getDir(3);
								let user_section = K.Router.getDir(4);
								
								this.renderUser(account_uuid, user_uuid, {
									section:user_section
								});
							break;
							default:
								this.renderAccount(account_uuid, {
									section:section
								});
						}
					break;
					default:
						throw new Error(`Invalid view ${baseDir} requested`);
				}
			}else{
				let nav = K.Router.getRequestFileNameBody() || 'index';
				switch (nav) {
					case 'index':
						this.renderHome();
					break;
					case '404':
						this.render404();
					break;
					case 'login':
						this.renderLogin();					
					break;
					default:
						throw new Error(`Invalid view ${nav} requested`);
				}
			}
		} else {
			this.renderLogin();
		}
	}
	renderAppContainer(){
		if(typeof(this.appContainer) === "undefined"){
			this.appContainer = new K.Containers.App(document.body);
		}else{
			this.appContainer.clear();
		}
	}
	renderAppInterface(){
		this.renderAppContainer();		
		if(typeof(this.Grid) === "undefined"){
			this.appContainer.clear();
			this.Grid = new K.Elements.Div({'className':'k-app'}).appendTo(this.appContainer);
			this.topbar = new TopBar();
			this.topbar.render().appendTo(this.Grid);
			this.main = new K.Elements.Div({'className':'k-main'});
			this.main.appendTo(this.Grid);
			this.topbar.on('logout', () => this.logout());
			this.topbar.on('navigate', () => this.parseRequest());
		}
		if(typeof(this.Grid.parentNode) === "undefined" || this.Grid.parentNode === null){
			this.appContainer.append(this.Grid);
		}
	}
	
	render(){
		K.Router.on('unauthorized', () => {
			if(this.view !== "login"){
				K.Cookie.unset('token');
				this.logout();
			}
		});
		
		K.Router.on('missing', () => {
			this.render404();
		});

		K.Router.on('navigate', () => {
			this.parseRequest();
		})

		this.parseRequest();
	}
}
