import { UsageList } from './UsageList.mjs';

export class Routines {
    constructor(Controller) {
        /*
        const eventHandler = new K.Observer(['navigate']);
        this.on = eventHandler.listen;
        this.notify = eventHandler.notify;
        */
        this.Controller = Controller;
        const eventHandler = new K.Observer(['abort']);
        this.on = eventHandler.listen;
        this.notify = eventHandler.notify;

        this.Container = new K.Elements.Div({'className':'k-routines'});
        this.UsageListInstance = undefined;
    }
    errorHandler(response) {
        console.error(response);
    }
    destroy() {
        if (typeof (this.UsageListInstance) !== "undefined") {
            this.UsageListInstance.destroy();
            delete this.UsageListInstance;
        }
        this.Container.clear();
    }

    renderUsageList() {
        this.destroy();
        if (typeof (this.UsageListInstance) === "undefined") {
            this.UsageListInstance = new UsageList(this);
        }
        //K.Router.navigate('Routines', '/routines');
        this.Container.append(this.UsageListInstance.render());
        return this.Container;
    }
   
}
