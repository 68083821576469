import { AccountList } from './AccountList.mjs';
import { ManageAccount } from './ManageAccount.mjs';
import { CreateAccount } from './CreateAccount.mjs';
import { AccountModule } from '../../../Modules/Account/Account.mjs';
export class Accounts {
    constructor(Controller) {
        /*
        const eventHandler = new K.Observer(['navigate']);
        this.on = eventHandler.listen;
        this.notify = eventHandler.notify;
        */
        this.Controller = Controller;
        const eventHandler = new K.Observer(['abort']);
        this.on = eventHandler.listen;
        this.notify = eventHandler.notify;

        this.Container = new K.Elements.Div({'className':'k-accounts'});
        this.AccountListInstance = undefined;
        this.CreateAccountInstance = undefined;
        this.ManageAccountInstance = undefined;
    }
    errorHandler(response) {
        console.error(response);
    }
    destroy() {
        if (typeof (this.ManageAccountInstance) !== "undefined") {
            this.ManageAccountInstance.destroy();
            delete this.ManageAccountInstance;
        }
        if (typeof (this.CreateAccountInstance) !== "undefined") {
            this.CreateAccountInstance.destroy();
            delete this.CreateAccountInstance;
        }
        if (typeof (this.AccountListInstance) !== "undefined") {
            this.AccountListInstance.destroy();
            delete this.AccountListInstance;
        }
        this.Container.clear();
    }
    openAccount(uuid) {
        /*
        this.destroy();
        if (typeof (this.ManageAccountInstance) === "undefined") {
            this.ManageAccountInstance = new ManageAccount(this);
            this.ManageAccountInstance.on('finished', function () {
                K.Router.navigate('Account', '/account');
                this.render();
            }.bind(this));
        }
        */
        K.Router.navigate('Account', '/account/' + uuid);

        //this.Container.append(this.ManageAccountInstance.render(uuid));
    }
    renderCreateAccount() {
        this.destroy();
        if (typeof (this.CreateAccountInstance) === "undefined") {
            this.CreateAccountInstance = new CreateAccount(this);
            this.CreateAccountInstance.on('abort', function () {
                K.Router.navigate('Accounts', '/accounts');
                //this.render();
            }.bind(this));
            this.CreateAccountInstance.on('finished', function () {
                K.Router.navigate('Accounts', '/accounts');
                //this.render();
            }.bind(this));
        }
        //K.Router.navigate('Account', '/accounts/create');
        this.Container.append(this.CreateAccountInstance.render());
        return this.Container;
    }
    renderManageAccount(account, data) {

        this.destroy();
        if (typeof (this.ManageAccountInstance) === "undefined") {
            this.ManageAccountInstance = new ManageAccount(this);
            this.ManageAccountInstance.on('abort', function () {
                K.Router.navigate('Account', '/account');
                //this.render();
            }.bind(this));
            this.ManageAccountInstance.on('finished', function () {
                K.Router.navigate('Account', '/accounts');
                //this.render();
            }.bind(this));
        }
        //K.Router.navigate('Account', '/account/' + account.uuid + '?action=manage');
        this.Container.append(this.ManageAccountInstance.render(account, data));
        return this.Container;
    }
    renderAccountList() {
        this.destroy();
        if (typeof (this.AccountListInstance) === "undefined") {
            this.AccountListInstance = new AccountList(this);
        }
        //K.Router.navigate('Accounts', '/accounts');
        this.Container.append(this.AccountListInstance.render());
        return this.Container;
    }
   
}
