
export class Dashboard {
    constructor(args) {
        /*
        const eventHandler = new K.Observer(['navigate']);
		this.on = eventHandler.listen;
		this.notify = eventHandler.notify;
        */
        this.Container = new K.Elements.Div({'className':'k-dashboard'});
        
    }
    
   
    render(){
        this.Container.clear();
        let Content = new K.Elements.Div({'className':'k-dashboard-view'}).appendTo(this.Container);        
        
        Content.append([
            K.DOM.createElement('div',{'className':'k-header'}).append([
                K.DOM.createElement('h2',{'children':[
                    K.DOM.createElement('i',{'className':'fa fa-briefcase'}),
                    K.DOM.createTextNode('Konton')
                ]})
                
            ])
        ]);
        Content.append([
            K.DOM.createElement('div',{'className':'k-header'}).append([
                K.DOM.createElement('h2',{'children':[
                    K.DOM.createElement('i',{'className':'fa fa-microchip'}),
                    K.DOM.createTextNode('Rutiner')
                ]})
                
            ])
        ]);
        return this.Container;
    }
}
