import { AccountModule } from '../../../Modules/Account/Account.mjs';

import { UserList } from '../Users/UserList.mjs';


export class ManageAccount {
	constructor(Controller, args) {
		
		this.Controller = Controller;
        this.Container = new K.Elements.Div({'className':'k-account-manage'});
		const eventHandler = new K.Observer(['abort','finished']);
		this.on = eventHandler.listen;
		this.notify = eventHandler.notify;
		this.uuid = undefined;
		this.section = undefined;
		this.account = undefined;
		this.users = undefined;
		
	}
	errorHandler(response){
		let alertNotice = new K.Components.Alert({
			'type':'danger'
		});
		console.error(response);
        if(typeof(response.status_text) !== "undefined"){
		    alertNotice.appendChild(K.DOM.createElement('h3',{'text':response.status_text}));
        }else{
            alertNotice.appendChild(K.DOM.createElement('h3',{'text':response.toString()}));
        }
		this.errorContainer.appendChild(alertNotice);
	}
	editColumnName(thRow){

		let uuid = thRow.account.uuid;
		let anchorElement = thRow.querySelector('.column-header');
		let quickRename = new K.Components.QuickRenamer(anchorElement);
		quickRename.on('done', (name) => {
			AccountModule.renameAccountColumn(uuid, name)
				.then((column) => {
					anchorElement.textContent = name;
				})
				.catch((err) => this.errorHandler(err));
		});
	}
	
	loadAccount(uuid){
		return new Promise((resolve, reject) => {
			new K.Request.GET(K.Config.api.url+'/describe/account/'+uuid)
			.then(resolve)
			.catch(reject);
		});
	}
	
	destroy(){
		
	}
	updateAccountPermissions(){
        
		return new Promise((resolve, reject) => {
			let countries = [];
			K.Config.supported_countries.forEach((country_code) => {
				let selectedCountry = this.manageAccountForm.querySelector('input[name="country"][value="'+country_code+'"]:checked');
				if(selectedCountry){
					countries.push(selectedCountry.value);
				}
			});
			
			
			new K.Request.POST(K.Config.api.url+'/update/account/'+this.uuid+'/permissions.json',{
				'countries':countries
			})
				.then((account_permission) => {
					this.account.countries = account_permission.countries;
					resolve();
					
				})
				.catch((err) => reject(err));
		});
    }
	updateAccount(){
        this.errorContainer.clear();
        this.submitButton.disabled = true;
        this.cancelButton.disabled = true;
		
        new K.Request.POST(K.Config.api.url+'/update/account/'+this.uuid+'.json',{
                'name':this.inputAccountName.value,
                'active':this.enableAccountToggle.checked ? 1 : 0,
                'date_start':this.accountStartDateInput.value,
                'date_expires':this.accountExpiresDateInput.value
            })
            .then((account) => {
                  this.account = account;
				  this.accountNameElement.innerText = this.account.name;
				  this.updateAccountPermissions()
				  	.then(() => {
				  		this.openAccountProperties();
					})
					.catch((err) => this.errorHandler(err));

            })
            .catch((err) => this.errorHandler(err));
        return false;
    }
	openEditAccount(){
		K.Router.navigate('Account', '/account/'+this.uuid+'/edit');
	}
	renderEditAccount(){
		this.errorContainer.clear();
		this.Content.clear();
        
        let listELement = new K.Elements.List();
        
        listELement.addChildNode(K.DOM.createElement('li',{'text':'Om du inte fyller i ett datumvärde i fältet "Aktivt t.o.m"  och "Aktivt fr.o.m" så innebär det att kontot är aktivt tillsvidare. Det är alltså inget krav.'}));
        const InfoCol = new K.Elements.Col(4, {padded:true}).append([
            K.DOM.createElement('h3',{'text':'Hjälp'}),
            listELement
        ]);
        const FormCol = new K.Elements.Col(8, {padded:true});
        
        this.submitButton = new K.Elements.Button({
            'type': 'submit',
            'buttonType':'primary',
            'block': true,
            'disabled': false,
            'buttonText': 'Spara ändringar'
        });
        this.cancelButton = new K.Elements.Button({
            'type': 'reset',
            'buttonType':'default',
            'block': true,
            'buttonText': 'Avbryt'
        });
        this.manageAccountForm = new K.Elements.Form({
            name:'account-create',
            id:'k-account-create-form'
        })
        .preventDefault()
        .on('submit', () => this.updateAccount());
        
        this.inputAccountName = new K.Elements.InputText({
            'placeholder': 'Välj ett namn på kontot',
            'className':'k-block',
			'value':this.account.name
        });
		this.inputAccountName.on('submit', () => this.updateAccount());
        
        this.enableAccountToggle = new K.Elements.CheckboxSlider({
			'text':'Aktivt konto',
			'checked':this.account.active ? true : false
		});
		this.accountStartDateInput = new K.Elements.Input({
            'type': 'date',
            'className':'k-block',
			'value':this.account.date_start
        });
        this.accountExpiresDateInput = new K.Elements.Input({
            'type': 'date',
            'className':'k-block',
			'value':this.account.date_expires
        });
		

		this.countrySelectorContainer = new K.Elements.Div({'className':'k-country-selector'});

		K.Config.supported_countries.map((country_code) => {
			let checkBox = new K.Elements.Checkbox({'value':country_code,'name':'country'});
			checkBox.check(this.account.countries.indexOf(country_code) !== -1);
			/*
			checkBox.on('click', (e) => {
				this.updateAccountPermissions();
			});
			*/
			let label;
			switch(country_code){
				case "se":
						label = K.DOM.createElement('label',{'children':[
							checkBox,
							K.DOM.createElement('i',{'className':'k-flag k-flag-s k-flag-se'}),
							K.DOM.createElement('span',{'className':'k-country-title','text':'Sverige'})
						]});
						
				break;
				case "no":
					label = K.DOM.createElement('label',{'children':[
						checkBox,
						K.DOM.createElement('i',{'className':'k-flag k-flag-s k-flag-no'}),
						K.DOM.createElement('span',{'className':'k-country-title','text':'Norge'})
					]});
				break;
				case "dk":
					label = K.DOM.createElement('label',{'children':[
						checkBox,
						K.DOM.createElement('i',{'className':'k-flag k-flag-s k-flag-dk'}),
						K.DOM.createElement('span',{'className':'k-country-title','text':'Danmark'})
					]});
				break;
				case "fi":
					label = K.DOM.createElement('label',{'children':[
						checkBox,
						K.DOM.createElement('i',{'className':'k-flag k-flag-s k-flag-fi'}),
						K.DOM.createElement('span',{'className':'k-country-title','text':'Finland'})
					]});
				break;
			}			
			this.countrySelectorContainer.append(label);
			
		});

        this.inputAccountName.on('change', () => {
            this.errorContainer.clear();
            this.submitButton.disabled = this.inputAccountName.value.length === 0;
            this.cancelButton.disabled = false;
        });
        this.manageAccountForm.append([
            new K.Elements.Div({'className':'k-row'}).append([
                new K.Elements.Col(12, {padded:true}).append([
                    K.DOM.createElement('h2',{'text':'Namn på konto'}),
                    this.inputAccountName,
					K.DOM.createElement('hr', {'className':'k-thin k-large'}),
                    K.DOM.createElement('h2',{'text':'Status'}),
                    this.enableAccountToggle,
					K.DOM.createElement('hr', {'className':'k-thin k-large'}),
					K.DOM.createElement('h2',{'text':'Länder'}),
                    this.countrySelectorContainer,
					K.DOM.createElement('hr', {'className':'k-thin k-large'}),
                    K.DOM.createElement('h3',{'text':'Aktivt fr.o.m'}),
                    this.accountStartDateInput,
					K.DOM.createElement('hr', {'className':'k-thin k-large'}),
                    K.DOM.createElement('h3',{'text':'Aktivt t.o.m'}),
                    this.accountExpiresDateInput
                ])
            ]),
			this.errorContainer,
            new K.Elements.Div({'className':'k-row'}).append([
                new K.Elements.Col(6, {padded:true}).append([this.submitButton]),
                new K.Elements.Col(6, {padded:true}).append([this.cancelButton])
            ])
        ]);
        FormCol.append(this.manageAccountForm);
        this.Content.append([
            K.DOM.createElement('div',{'className':'k-header'}).append([
                K.DOM.createElement('h1',{'children':[
                    K.DOM.createElement('i',{'className':'fa fa-briefcase'}),
                    K.DOM.createTextNode('Redigera konto')
                ]})
            ])            
        ]);
        this.Content.append([
            new K.Elements.Div({'className':'k-row'}).append([
                FormCol,
                InfoCol
            ])
            
        ]);
        
        this.cancelButton.on('click', () => this.renderAccountProperties());

    }
	openAccountProperties(){
		K.Router.navigate('Account', '/account/'+this.uuid+'/properties');
	}
	renderAccountProperties(){
		this.section = 'properties';
		this.Content.clear();
		this.accountPropertiesButton.classList.add('k-button-selected');
		this.usersButton.classList.remove('k-button-selected');
		this.eventLogButton.classList.remove('k-button-selected');
		
		let editButton = new K.Elements.Button({
			'block': true,
			'buttonType':'plain',
			'buttonText': 'Redigera'
		});
		editButton.click(() => this.openEditAccount());
		let countryContainer = new K.Elements.Div({'className':'k-country-selector'});

		K.Config.supported_countries.map((country_code) => {
			let label;
			switch(country_code){
				case "se":
					label = K.DOM.createElement('label',{'className':(this.account.countries.indexOf(country_code) === -1 ? 'k-disabled':''),'children':[
						K.DOM.createElement('i',{'className':'k-flag k-flag-s k-flag-se'}),
						K.DOM.createElement('span',{'className':'k-country-title','text':'Sverige'})
					]});						
				break;
				case "no":
					label = K.DOM.createElement('label',{'className':(this.account.countries.indexOf(country_code) === -1 ? 'k-disabled':''),'children':[
						K.DOM.createElement('i',{'className':'k-flag k-flag-s k-flag-no'}),
						K.DOM.createElement('span',{'className':'k-country-title','text':'Norge'})
					]});
				break;
				case "dk":
					label = K.DOM.createElement('label',{'className':(this.account.countries.indexOf(country_code) === -1 ? 'k-disabled':''),'children':[
						K.DOM.createElement('i',{'className':'k-flag k-flag-s k-flag-dk'}),
						K.DOM.createElement('span',{'className':'k-country-title','text':'Danmark'})
					]});
				break;
				case "fi":
					label = K.DOM.createElement('label',{'className':(this.account.countries.indexOf(country_code) === -1 ? 'k-disabled':''),'children':[
						K.DOM.createElement('i',{'className':'k-flag k-flag-s k-flag-fi'}),
						K.DOM.createElement('span',{'className':'k-country-title','text':'Finland'})
					]});
				break;
			}			
			countryContainer.append(label);
			
		});

		this.Content.append([
			new K.Elements.Div({'className':'k-row'}).append([
				editButton,
				new K.Elements.Col(12, {padded:true}).append([
					K.DOM.createElement('h2',{'text':'Namn på konto'}),
					K.DOM.createElement('p',{'text':this.account.name}),
					K.DOM.createElement('hr', {'className':'k-thin k-large'}),
					K.DOM.createElement('h2',{'text':'Status'}),
					K.DOM.createElement('p',{'text':this.account.active ? 'Aktivt konto':'Inaktivt konto'}),					
					K.DOM.createElement('hr', {'className':'k-thin k-large'}),
					K.DOM.createElement('h2',{'text':'Länder'}),
                    countryContainer,
					K.DOM.createElement('hr', {'className':'k-thin k-large'}),
                    K.DOM.createElement('h3',{'text':'Aktivt fr.o.m'}),
					K.DOM.createElement('p',{'text':this.account.date_start}),
					K.DOM.createElement('hr', {'className':'k-thin k-large'}),
					K.DOM.createElement('h3',{'text':'Aktivt t.o.m'}),
					K.DOM.createElement('p',{'text':this.account.date_expires})
				])
			])
		]);
		
	}
	openEventLog(){
		K.Router.navigate('Account', '/account/'+this.uuid+'/events');
	}
	
	renderEventLog(){
		this.section = 'events';
		this.Content.clear();
		this.accountPropertiesButton.classList.remove('k-button-selected');
		this.usersButton.classList.remove('k-button-selected');
		this.eventLogButton.classList.add('k-button-selected');
		
		this.Content.append([
			new K.Elements.Div({'className':'k-row'}).append([
				new K.Elements.Col(12, {padded:true}).append([
					K.DOM.createElement('h2',{'text':'Händelser'}),
					K.DOM.createElement('p',{'text':'Lista på inloggningar, exporter och andra aktiviteter som skett'})
				])
			])
		]);
		
	}
	openUserList(){
		K.Router.navigate('Account', '/account/'+this.uuid+'/users');
	}
	renderUserList(){
		this.section = 'users';
		this.Content.clear();
		this.accountPropertiesButton.classList.remove('k-button-selected');
		this.usersButton.classList.add('k-button-selected');
		this.eventLogButton.classList.remove('k-button-selected');
		this.UserListInstance = typeof(this.UserListInstance) !== "undefined" ? this.UserListInstance : new UserList(this);
		this.Content.append(this.errorContainer);
		this.Content.append(this.UserListInstance.render(this.uuid));
	}
	openUser(uuid){
		K.Router.navigate('User', '/account/'+this.uuid+'/user/'+uuid);
	}
	
	render(uuid, params){
		this.uuid = uuid;
		params = params ?? {};
		K.Utils.forOwn(params, (val, key) => {
			this[key] = val;
		});
		
		this.Container.clear();
		this.Top = new K.Elements.Div({'className':'k-account-manage-top'}).appendTo(this.Container);
		this.Content = new K.Elements.Div({'className':'k-account-manage-content'}).appendTo(this.Container);
		this.errorContainer = new K.Elements.Div({'className':'k-error-panel'});
		
		
		const backButton = new K.Elements.Button({
			'type': 'button',
			'buttonType':'plain',
			'block': false,
			'buttonText': '❮'
		});
		this.accountPropertiesButton = new K.Elements.Button({
			'type': 'button',
			'buttonType':'plain',
			'block': false,
			'icon':'briefcase',
			'buttonText': 'Egenskaper för konto'
		});
		this.usersButton = new K.Elements.Button({
			'type': 'button',
			'buttonType':'plain',
			'block': false,
			'icon':'users',
			'buttonText': 'Användare'
		});
		this.eventLogButton = new K.Elements.Button({
			'type': 'button',
			'buttonType':'plain',
			'block': false,
			'icon':'file-contract',
			'buttonText': 'Händelser'
		});
		this.accountNameElement = K.DOM.createElement('span',{'id':'k-account-manage-account-name','text':''});
				
		this.loadAccount(uuid)
			.then((account) => {
				this.account = account;
				this.users = account.users ?? [];
				this.accountNameElement.innerText = this.account.name;
				
				this.Top.append([
					K.DOM.createElement('div',{'className':'k-account-manage-account-title'}).append([
						K.DOM.createElement('h1',{'children':[
							backButton,
							this.accountNameElement					
						]})
					]),
					K.DOM.createElement('div',{'className':'k-option-panel'}).append([
						K.DOM.createElement('div',{'className':'k-inline-block','children':[this.accountPropertiesButton, this.usersButton,this.eventLogButton]})
					])				
					
				]);
				switch(this.section){
					case "edit":
						this.renderEditAccount();
					break;
					case "users":
						this.renderUserList();
					break;
					case "events":
						this.renderEventLog();
					break;
					default:
						this.renderAccountProperties();
				
				}
				
		
			})
			.catch((err) => this.errorHandler(err));
		
		backButton.on('click',(e) => this.notify('finished'));
		this.accountPropertiesButton.on('click', () => this.openAccountProperties());
		this.usersButton.on('click', () => this.openUserList());
		this.eventLogButton.on('click',() => this.openEventLog());
		return this.Container;

	}
}
