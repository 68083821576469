export class Page404 {
    constructor(args) {
        const eventHandler = new K.Observer(['back']);
		this.on = eventHandler.listen;
		this.notify = eventHandler.notify;
        this.Container = new K.Elements.Div({'className':'k-404'});
        
    }
    render(){
        this.backButton = new K.Elements.Button({
            'type': 'submit',
            'buttonType':'primary',
            'block': true,
            'buttonText': 'Tillbaka'
        });
        
        this.Container.append([
            K.DOM.createElement('img',{'className':'k-logo','src':'/assets/logo.png'}),
            K.DOM.createElement('h3',{'className':'k-text-center','text':'Oops'}),
            K.DOM.createElement('p',{'className':'k-text-center','text':'Den där var vi inte beredd på. Beklagar att ni hittat en sida som inte ledde någonstans.'}),
            K.DOM.createElement('hr', {'className':'k-thin k-large'}),
            this.backButton
        ]);
        this.backButton.click((e) => this.notify('back'));
        return this.Container;
    }
}
